import lozad from 'lozad';
const observer = lozad(); observer.observe();
import './mkx-mask.js';
import './address.js';
import './datalayer.js';
import './main.js';
import('./fontawesome/all.min.js');

// Botstrap
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
