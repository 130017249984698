import { reloadicons } from "./svg";
import Modal from 'bootstrap/js/dist/modal'

export function getURLParameter(parameter) {
	return decodeURIComponent((new RegExp('[?|&]' + parameter + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [, ""])[1].replace(/\+/g, '%20')) || null;
}

export function showRatingWhenInView(elements) {
	const thumbRatingOptions = {
		hoverOnClear: false,
		theme: 'krajee-svg',
		disabled: true,
		showCaption: false,
		showCaptionAsTitle: false,
		clearButtonBaseClass: 'displaynone',
		hoverEnabled: false,
		size: 'xs',
	};
	document.querySelectorAll(elements)?.forEach(thumb => {
		executeWhenInViewOnce(thumb,() =>  $(thumb).rating(thumbRatingOptions));
	});
}

/* carrinho */
export function openNav() {
	$("#miniCart").addClass("aberto");
	$("#main-content").addClass("aberto");
	$("body").addClass("aberto");
	$("body.aberto").addClass('carrinho');
	$("html").addClass("carrinho");
	$("#overlay-geral").addClass("active");
	reloadicons("iconcart");
}

export function closeNav() {
	$("#miniCart").removeClass("aberto");
	$("#main-content").removeClass("aberto");
	$("body").removeClass("aberto");
	$("body").removeClass("carrinho");
	$("#overlay-geral").removeClass("active");
	$('html').removeClass("carrinho");
}

/**
* Abrir overlay
*/
export function showOverlay(overlay) {
	const element = document.getElementById(overlay);
	if (!element) return;
	const modal = new Modal(element);
	modal.show();
}

/**
* Fechar overlay
*/
export function hideOverlay(overlay, delaytime = 0) {
	const element = document.getElementById(overlay);
	if (!element) return;
	const modal = new Modal(element);
	if (delaytime === 0) {
		return modal.hide();
	}
	setTimeout(() => modal.hide(), delaytime);
}

/* Validar forms */
export function validateForm(form) {
	if (form.hasClass('mkx-validator')) { // Classe para usar o validador
		var valid = true; // Define previamente como válido
		var fields = ""; // Campos que estão com erro
		if (form.find('.check-validator').length > 0) { // Classe para validar checks
			form.find('.check-validator').each(function () {
				if ($(this).find('input:checked').length < 1) {
					valid = false;
					if ($(this).attr('data-alert') != "") {
						fields += '<li>- <strong>' + $(this).attr('data-alert') + '</strong></li>';
					}
					$(this).addClass('not-valid');
				}
			});
		}
		if (form.find('.required').length > 0) {
			form.find('.required').each(function () {
				if ($(this).val() == "" || $(this).val() == undefined || $(this).val() == '00/00/0000') { // Valor vazio
					valid = false;
					if ($(this).attr('data-alert') != "") {
						fields += '<li>- <strong>' + $(this).attr('data-alert') + '</strong></li>';
					}
					$(this).parent().parent().addClass('has-error');
				} else if (!!$(this).attr('data-confirm')) { // se for um campo de confirmação de outro campo (ex: confirme seu e-mail, sua senha)
					if ($(this).val() != form.find($(this).attr('data-confirm')).val()) {
						valid = false;
						fields += '<li>- <strong>' + $(this).attr('data-confirm-alert') + '</strong></li>';
						$(this).parent().parent().addClass('has-error');
					}
				}
			});
		}
		if (valid == false) {
			form.find('.form-success').slideUp();
			form.find('.main-alert').html('<a class="close" title="Fechar">×</a>');
			form.find('.main-alert').append(form.find('.main-alert').attr('data-alert'));
			form.find('.main-alert').append('<ul>' + fields + '</ul>');
			form.find('.main-alert').fadeIn();
			form.find('.main-alert').removeClass("displaynone");
			form.find('.not-valid .alert').fadeIn();
			return false;
		} else {
			return true;
		}
	} else {
		return true;
	}
}

export function copyTextToClipboard(text) {
	if (!navigator.clipboard) {
		const textArea = document.createElement('textarea');
		textArea.value = text;
		textArea.style.top = "0";
		textArea.style.left = "0";
		textArea.style.position = "fixed";
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			const success = document.execCommand('copy');
			const msg = success ? 'successful' : 'unsuccessful';

		} catch (err) { }
		document.body.removeChild(textArea);
		return true;
	}
	navigator.clipboard.writeText(text).then(function () {
	}).catch(function (err) { console.error(err); })
	return true;
}


/* Forms em ajax */
export function ajaxForms(form) {

	if (form.hasClass('ajax')) {
		pageLoadingOn();
		var url = form.attr('action');
		var data = form.serialize();
		data = data + "&ajax=";
		$.ajax({
			type: "POST",
			url: url,
			data: data,
			dataType: "json",
			success: function (res) {
				if (res.reset != undefined) {
					if (res.reset == 1) {
						$(':input', '#' + form.attr('id')).not(':button, :submit, :reset, :hidden').val('').removeAttr('checked').removeAttr('selected');
					}
				}
				if (res.error != undefined) {
					form.find('.form-success').slideUp();
					form.find('.main-alert').html('<a class="close" title="Fechar">×</a>');
					form.find('.main-alert').append(res.error);
					//form.find('.main-alert').fadeIn();
					form.find('.main-alert').removeClass("displaynone");
					if (form.find('.secure-image').length > 0) {
						form.find('.secure-image #reload-image').trigger('click');
					}
					pageLoadingOff();
					return false;
				} else if (res.location != undefined) {
					window.location.href = res.location;
				} else if (res.html != undefined && res.id != undefined) {
					$("#" + res.id).html(res.html);
					if (res.addressId != undefined) {
						$("#" + res.id).attr("id", "address-" + res.addressId);
						$("input[name=address_id]").val(res.addressId);
					}
					// NEWMKX
					if (res.success != undefined) {
						form.find('.main-alert').slideUp();
						form.find('.form-success').removeClass('displaynone');
						form.find('.form-success').html('<a class="close" title="Fechar">×</a>');
						form.find('.form-success').append(res.success);
						form.find('.form-success').fadeIn();
					}

					hideOverlay("overlay-address-update", 2500);

					pageLoadingOff();
				} else if (res.customEffect != undefined) {
					switch (res.customEffect) {
						case "checkoutPersonUpdate": // Checkout 2 - Atualizar dados do cliente
							var firstName = form.find('#personName').val().split(" ");
							firstName = firstName[0];
							$('#step-1 .person-data .hello span').html(firstName);
							$('#step-1 .person-data .name span').html(form.find('#personName').val());
							$('#step-1 .person-data .document span').html(form.find('#personCPF').val());
							$('#step-1 .person-data .phone span').html(form.find('#personPhone').val());
							$('#step-1 .person-data .email span').html(form.find('#personEmail').val());
							// NEWMKX
							hideOverlay("overlay-person-update", 0);
							pageLoadingOff();
							break;
					}
				} else if (res.success != undefined) {
					form.find('.main-alert').slideUp();
					form.find('.form-success').removeClass('displaynone');
					form.find('.form-success').html('<a class="close" title="Fechar">×</a>');
					form.find('.form-success').append(res.success);
					form.find('.form-success').fadeIn();
					if (form.hasClass('resetFields')) {
						form.trigger("reset");
					}
					var loginTrigger = form.find('input[name=loginTrigger]').val();

					if (res.nItens != undefined) {
						if (res.nItens == 1) {
							$("#cart-box p strong").html("0" + res.nItens + " produto");
						} else {
							if (res.nItens < 10) {
								$("#cart-box p strong").html("0" + res.nItens + " produtos");
							} else {
								$("#cart-box p strong").html("" + res.nItens + " produtos");
							}
						}
						$("#cart-box span strong").html(res.total);
					}

					pageLoadingOff();
					// NEWMKX
					//hideOverlay("overlay-homologacao",0);
					//hideOverlay("overlay-add-cart",1000);

					if (loginTrigger == 'ok') {
						// NEWMKX
						//hideOverlay("overlay-login",0);
						$('.add-favorites a').attr("data-login", '');
						$('.add-favorites a').trigger('click');
					}

					if (url == '/meus-enderecos/') {
						setTimeout(function () {
							window.location.reload();
						}, 2000);
					}
				}
			},
			error: function () {
				form.find('.form-success').slideUp();
				form.find('.main-alert').html('<a class="close" title="Fechar">×</a>');
				form.find('.main-alert').append('Erro, tente denovo!');
				//form.find('.main-alert').fadeIn();
				form.find('.main-alert').removeClass("displaynone");
				if (form.find('.secure-image').length > 0) {
					form.find('.secure-image #reload-image').trigger('click');
				}
				pageLoadingOff();
				return false;
			}
		});

	} else {
		return true;
	}

}
window.pageLoadingOff = pageLoadingOff;
window.pageLoadingOn = pageLoadingOn;

export function pageLoadingOn() {
	$('body').append('<div class="pageLoading"><div class="loading"><div id="circularG"><div id="circularG_1" class="circularG"></div><div id="circularG_2" class="circularG"></div><div id="circularG_3" class="circularG"></div><div id="circularG_4" class="circularG"></div><div id="circularG_5" class="circularG"></div><div id="circularG_6" class="circularG"></div><div id="circularG_7" class="circularG"></div><div id="circularG_8" class="circularG"></div></div></div></div>');
	$('.pageLoading').fadeIn(200);
}

export function pageLoadingOff() {
	$('.pageLoading').delay(100).queue(function () {
		$(this).addClass("off");
		$(this).dequeue();
	});
	$('.pageLoading').fadeOut(200, function () { $('.pageLoading').remove(); });
}

export function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

export function getPage() {
	return document.querySelector("title[data-type]")?.getAttribute('data-type');
}

/**
 * Returns an array of parents of given element that matches the css selector
 * 
 * @param Element element 
 * @param string selector 
 * @returns Array
 */
export function queryParentsAll(element, selector) {
  const parents = [];
  let current = element;
  while (current.parentElement) {
    if (current.parentElement.matches(selector)) {
      parents.push(current.parentElement);
    }
    current = current.parentElement;
  }
  return parents;
}

/**
 * Determina se a página foi recarregada
 * 
 * @returns boolean
 */
export function pageReloaded() {
	return (
		window.performance &&
			window.performance
				.getEntriesByType('navigation')
				.map((nav) => nav.type)
				.includes('reload')
	);
}

export function loadFavoristos() {
	window.favoritosIds = document.querySelector("script#favoritosIds")?.textContent;
}

/**
 * Quando um elemento esta no campo de visão dado um threshold em options.threshold (entre 0 e 1)
 * É executado a função action um única vez.
 * 
 * @param {*} element Element a ser observado
 * @param {*} callback  Callback que é a função a ser executada
 * @param {*} options Opções
 */
export function executeWhenInViewOnce(element, callback, options = {}) {
	const threshold = options.threshold ?? 1;
	const observer = new IntersectionObserver((entries, observer) => {
		if (entries[0].isIntersecting === true) {
			callback();
			observer.disconnect();
		}
	}, { threshold });
	observer.observe(element);
}