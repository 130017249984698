import 'slick-carousel';
import { reloadicons } from './svg.js';
import { closeAllMenus, closeNavMobile } from './mobile.js';
import { dispatchViewCartEvent } from './events.js';
import { pageLoadingOn, pageLoadingOff, showRatingWhenInView } from './utilities.js';
import Modal from 'bootstrap/js/dist/modal';

jQuery(async function () {

	await import('qtip2')
	await import('./svg.js');
	await import('./carrinho.js');
	await import('./mobile.js');
	await import('jquery-ui/ui/widgets/autocomplete');
	await import('./rating/star-rating.js');
	await import('./rating/theme.js');
	await import('./favoritos.js');
	const { default: Blazy } = await import('blazy')

	if ($("#loader").length > 0) {
		$("#loader").fadeOut("");
	}

	$('.continuar-compra-btn').on('click', function () {
		closeNav();
	})

	/*
	* Fecha o alert
	*/
	$('body').on('click', '.alert a.close', function () {
		if ($(this).parent().parent().hasClass('has-error')) {
			$(this).parent().removeAttr("style");
			$(this).parent().addClass("displaynone");
			$(this).parent().parent().removeClass('has-error');
			if ($(this).parent().hasClass('alert-add')) {
				$(this).parent().remove();
			}
		} else {
			$(this).parent().removeAttr("style");
			$(this).parent().addClass("displaynone");
		}
	});

	/*
		Menu todos departamentos
	*/
	$('#overlay-geral ').mouseout(function () {
		var anterior = $('.toda-loja').find('.active-menu-d');
		anterior.removeClass('active-menu-d');
		anterior.addClass('d-none');
	});

	$(".toda-loja .item-menu>a").on({
		'mouseover': function (e) {
			var anterior = $('.toda-loja-itens.active-menu-d');
			anterior.removeClass('active-menu-d');
			anterior.addClass('d-none');
			var url = $(this).attr('data-url');
			//console.log(url);
			var ul = $('.toda-loja-itens#' + url);
			ul.removeClass('d-none');
			ul.addClass('active-menu-d');
		},
	});

	$("#listaCategoria .itemMenu a").on({
		'mouseover':function(e){
			var id = $(this).parent().parent().attr("data-id");
			var anterior = $('#conteudoCategoria'+id).find('.active-menu-d');
			anterior.removeClass('active-menu-d');
			anterior.addClass('d-none');
			var url = $(this).attr('data-url');
			var ul = $('#conteudoCategoria'+id).find('#'+url);
			ul.removeClass('d-none');
			ul.addClass('active-menu-d');
		},
	});

	/*
	Ajusta o menu, apaga elementos que cairam na linha
	*/
	var fullmenu;
	ajustaMenu();
	
	$(window).on("resize", function() {
		ajustaMenu();
		menuMobileCheck();
	});

	function menuMobileCheck() {
		if ($(window).innerWidth() > 991) {
			closeNavMobile();
		}
	}
 
	function ajustaMenu() {
		var menuWidth;
		fullmenu = $("#desktop-menu-header > .nav").outerWidth();
		$("#desktop-menu-header > .nav > li").each(function(i) {
			if ($(this).attr("data-width")) {
				menuWidth = $(this).attr("data-width");
			} else {
				menuWidth = $(this).outerWidth();
				$(this).attr("data-width",$(this).outerWidth());
			}
			
			if ((fullmenu - menuWidth) >= 0) {
				fullmenu = fullmenu - menuWidth;
				//$(this).css('visibility', "visible");
				$(this).removeClass("displaynone");
			} else {
				$(this).addClass("displaynone");
				//$(this).css('visibility', "collapse");
			}
		});
		$("#desktop-menu-header").css("height", "auto");
		$("#desktop-menu-header").css("overflow", "initial");
	}

	/*
	* Autocomplete da busca
	*/
	var cache = {}, lastXhr;
	//var autoMax = $('#form-search input').outerWidth();
	$("input.search-topo").autocomplete({
		minLength: 2,
		position: {
			my: "left top",
			at: "left bottom"
		},
		source: function (request, response) {
			var term = request.term;
			if (term in cache) {
				response(cache[term]);
				return;
			}
			lastXhr = $.getJSON("/pesquisa/", request, function (data, status, xhr) {
				var results;
				if (xhr === lastXhr) {
					results = $.map(data, function (item) {
						return {
							value: item.value,
							img: item.img,
							label: item.label,
							id: item.id,
							price: item.price,
							total: item.total
						};
					});
					cache[term] = results;
					response(results);
				}
			});
		},
		create: function () {
			$(this).data("uiAutocomplete")._renderItem = function (ul, item) {
				if (item.label == 'inicio') {
					return $("<li/>").appendTo(ul);

				} else if (item.label == 'final') {
					return $("<li/>")
						.data("item.autocomplete", item)
						.append("<a class='final'>Ver todos os " + item.total + " produtos </a>")
						.appendTo(ul);
				} else if (item.label == 'categoria') {
					return $("<li/>")
						.data("item.autocomplete", item)
						.append("<a class='categoria'>" + item.value + "</a>")
						.appendTo(ul);
				} else if (item.label == 'categorias') {
					return $("<li/>")
						.data("item.autocomplete", item)
						.append("<span class='categorias'>" + item.value + "</span>")
						.appendTo(ul);
				} else {
					if (item.value != null) {
						return $("<li/>")
							.data("item.autocomplete", item)
							.append("<a class='result'><div class='img'><img src='" + item.img + "' /></div><div class='info'><div class='name'>" + item.value + "</div><div class='price'>" + item.price + "</div></div></a>")
							.appendTo(ul);
					}
				}
			}
		},
		select: function (event, ui) {
			window.location.href = ui.item.id;
		}
	});

	var bLazy = new Blazy({
		offset: 20,
		selector: '.b-lazy',
	});
	$(".form-search-header").on("click", "i", function () {
		var form = $(this).parent();
		var term = form.find('input').val();

		if (term == "") {
			if (form.find('input').hasClass("active")) {
				form.find('input').removeClass("active");
			} else {
				form.find('input').addClass("active");
				form.find('input').focus();
			}
		} else {
			form.submit();
		}
	});

	$(document).on("blur", ".form-search-header:visible input", function () {
		if ($(this).hasClass("active")) {
			$(this).removeClass("active");
			$(this).val("");
		}
	});

	// Copiar código do Pix Emv
	$(document).on('click', "button#pix-emv-code, button#copyable-code", function () {
		const button = $(this);
		const originalText = button.find('span').html();
		const value = button.val();
		if (copyTextToClipboard(value)) {
			button.find('span').html('Copiado!');
			setTimeout(function () {
				button.find('span').html(originalText);
			}, 2000);
		}
	});

	/*
	* Atualiza informações do banco do brasil, quando clica no imprimir boleto
	*/
	$('#btn-finalizar-bb').on("click", function(){
		var orderId = $(this).attr("data-id");
		$.ajax({
			type: "POST",
			url: "",
			data: "orderId="+orderId+"&atualizaStatusBB=true",
			dataType:"json",
			success: function(json){
				var tpPagamento = $('input[name=tpPagamento]');
				if (tpPagamento.val()==2){
					tpPagamento.val(21);
				}
				pageLoadingOff();
			}
		});
	});	

	$('body').on('submit', 'form', function (event) {
		var form = $(this);

		if (form.attr('id') === 'form-payment-gateway-card' || form.attr('id') === 'form-payment-gateway') {
			return true;
		}

		// Não valida para form da RDStation
		// if ($(this).parent().parent().hasClass('.rdstation-popup')) {
		// 	return true;
		// }

		// NEWMKX
		var validacao = validateForm(form);
		if (!validacao) {
			return false;
		}

		if (form.attr("target") == undefined || form.attr("target") != "_blank") {
			pageLoadingOn();
		}

		if (form.find("input[name=g-recaptcha-response]").length == 1 && form.attr('id') != 'form-cielo' && form.attr('id') != 'form-paymet' && form.attr('id') != 'form-mercadopago') {

			//Google reCAPTCHA v3
			event.preventDefault();
			var gInput = form.find("input[name=g-recaptcha-response]");
			var site_key = gInput.attr("data-sitekey");
			var action_name = gInput.attr("data-action");
			grecaptcha.ready(function () {
				grecaptcha.execute(site_key, { action: action_name })
					.then(function (token) {
						gInput.val(token);
						var validacao = ajaxForms(form);
						if (!validacao) {
							return false;
						}
					});
			});
			//FIM Google reCAPTCHA v3
		} else {
			var validacao = ajaxForms(form);
			if (!validacao) {
				return false;
			}
		}
	});

	// Abrir Dropdown de Login para visualizar preços
	$(document).on("click","#openLogin",function(e){
		e.preventDefault();
		if ($(window).innerWidth() > 991) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			$("#header .desktop-header-itens .signin").trigger("click");
        } else {
			window.location.href='/login/';
		}
	});


	/**
	* Tabs
	*/
	$('.mkx-tabs a').click(function () {
		if (!$(this).hasClass('active')) {
			var tab = $(this).parent().parent().attr('data-tab'); // Referência a qual aba
			var panel = $(this).attr('data-panel'); // Referência a qual painel

			$(".mkx-tabs[data-tab='" + tab + "'] a").removeClass('active'); // Desmarca todos
			$(this).addClass('active'); // Marca o clicado

			$(".mkx-panes[data-tab='" + tab + "'] > div").hide().css({ 'paddingLeft': 25, 'opacity': 0 }); // Efeitos a gosto
			$(".mkx-panes[data-tab='" + tab + "'] #" + panel).css({ 'display': 'block' }).animate({ 'paddingLeft': 0, 'opacity': 1 }); // Efeitos a gosto
		}
	})

	/* [inicio] - Parallax */
	if ($(".banner-parallax").length > 0) {
		$(".img-parallax").css("background-image", "url(" + $(".banner-parallax").data("src") + ")");
		var parallaxInicio = ($(".banner-parallax").offset().top - $(window).innerHeight());
		var parallaxFinal = ($(".banner-parallax").offset().top + $(".banner-parallax").height());
		var parallaxDiff = $(".banner-parallax").data("area") / (parallaxFinal - parallaxInicio);
		if ($(window).scrollTop() > parallaxInicio && $(window).scrollTop() < parallaxFinal) {
			var coords = 'center ' + (($(window).scrollTop() - parallaxInicio) * parallaxDiff + ((100 - $(".banner-parallax").data("area")) / 2)) + '%';
			$(".img-parallax").css("background-position", coords);
		}
	}
	$(window).scroll(function (event) {
		if ($(".banner-parallax").length > 0) {
			var parallaxInicio = ($(".banner-parallax").offset().top - $(window).innerHeight());
			var parallaxFinal = ($(".banner-parallax").offset().top + $(".banner-parallax").height());
			var parallaxDiff = $(".banner-parallax").data("area") / (parallaxFinal - parallaxInicio);
			if ($(window).scrollTop() > parallaxInicio && $(window).scrollTop() < parallaxFinal) {
				var coords = 'center ' + (($(window).scrollTop() - parallaxInicio) * parallaxDiff + ((100 - $(".banner-parallax").data("area")) / 2)) + '%';
				$(".img-parallax").css("background-position", coords);
			}
		}
	});

	showRatingWhenInView(".product-thumb-info .ratings .rating-box input");

	$('.product-thumb .product-thumb-price .product-quantity input[type=button]').on("click", function (e) {
		if ($(this).hasClass('qtyminus')) {
			var act = "rem";
		} else {
			var act = "add";
		}
		var element = $(this).parent().find("input[name=amount]");
		var amount = element.val();
		if (act == 'rem' && amount > 1) {
			amount--;
		} else if (act == 'add') {
			amount++;
		}
		element.val(amount);
	});

	
	$('.bannerPrincipal').slick({
		dots: false,
		arrows: true,
		autoplay: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		touchThreshold: 1000,
		responsive: [
			{
				breakpoint: 2000,
				settings: {
					centerMode: false,
					centerPadding: '50px'
				}
			},
			{
				breakpoint: 3840,
				settings: {
					centerMode: true,
					centerPadding: '20%'
				}
			},
		],
	});

	$('.bannerPrincipalMobile').slick({
		dots: true,
		arrows: false,
		autoplay: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		touchThreshold: 1000,
		mobileFirst: true,
	});

	$('.bannersmarcas-carousel').slick({
		arrows: false,
		dots: false,
		autoplay: false,
		infinite: true,
		slidesToShow: 4,
		mobileFirst: true,
		touchThreshold: 1000,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 9,
					slidesToScroll: 9
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 6
				}
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			}
		]
	});

	$(document).on("click", "#overlay-geral", closeAllMenus);

	$(document).on("click", ".openNav", () => openNav());
	$(document).on("click", ".closeNav", () => closeNav());

	// Abrir Dropdown Menu
	$(document).on("click", ".desktop-header ul li.user-dropdown .signin", function (e) {
		e.preventDefault();
		$(".desktop-header ul li.user-dropdown .dropdown-menu ").css("display", "block");
		$(".desktop-header ul li.user-dropdown .dropdown-menu ").addClass("active");
		$(".desktop-header ul li.user-dropdown .dropdown-menu ").css("visibility", "visible");
		$("#overlay-geral").addClass("active");
	});
	// Fechar Dropdown Menu
	$(document).on("click", "#header .dropdownuserlogin > div span.iconfechar svg", function () {
		$(".desktop-header ul li.user-dropdown .dropdown-menu ").css("display", "none");
		$(".desktop-header ul li.user-dropdown .dropdown-menu ").css("visibility", "collapse");
		$(".desktop-header ul li.user-dropdown .dropdown-menu ").removeClass("active");
		$("#overlay-geral").removeClass("active");
	});

	// Abrir Dropdown Menu
    $(document).on("click",".desktop-header ul li.atendimento a.dropatendimento",function(e){
		e.preventDefault();
        $(".desktop-header ul li.atendimento .dropdown-menu ").css("display","block");
        $(".desktop-header ul li.atendimento .dropdown-menu ").addClass("active");
        $(".desktop-header ul li.atendimento .dropdown-menu ").css("visibility","visible");
        $("#overlay-geral").addClass("active");
    });
    // Fechar Dropdown Menu
    $(document).on("click","#header .dropdownatendimento > div span.iconfechar svg",function(){
        $(".desktop-header ul li.atendimento .dropdown-menu ").css("display","none");
        $(".desktop-header ul li.atendimento .dropdown-menu ").css("visibility","collapse");
        $(".desktop-header ul li.atendimento .dropdown-menu ").removeClass("active");
        $("#overlay-geral").removeClass("active");
    });

	// Fechar Menu clicando em qualquer lugar da página
	$(document).on("click", "#overlay-geral.active", function () {
		if ($(".desktop-header ul li.user-dropdown .dropdown-menu").hasClass("active")) {
			$("#header .dropdownuserlogin > div span.iconfechar svg").trigger("click");
		}

		if ($(".desktop-header ul li.atendimento .dropdown-menu").hasClass("active")) {
			$("#header .dropdownatendimento > div span.iconfechar svg").trigger("click");
		}
	});

	// Exibir tooltip para Fast Server
	if ($('.qtiptxt').length > 0) {
		$('.qtiptxt').qtip({
			hide: {
				fixed: true,
				delay: 1000
			},
			content: {
				text: $('.tooltiptext')
			},
			style: {
				classes: 'qtip-bootstrap'
			},
			position: { my: 'bottom-left', at: 'top-right' }
		});
	}
});

export function getURLParameter(parameter) {
	return decodeURIComponent((new RegExp('[?|&]' + parameter + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [, ""])[1].replace(/\+/g, '%20')) || null;
}

/* carrinho */
export function openNav() {
	$("#miniCart").addClass("aberto");
	$("#main-content").addClass("aberto");
	$("body").addClass("aberto");
	$("body.aberto").addClass('carrinho');
	$("html").addClass("carrinho");
	$("#overlay-geral").addClass("active");
	reloadicons("iconcart");
	dispatchViewCartEvent();
}

export function closeNav() {
	$("#miniCart").removeClass("aberto");
	$("#main-content").removeClass("aberto");
	$("body").removeClass("aberto");
	$("body").removeClass("carrinho");
	$("#overlay-geral").removeClass("active");
	$('html').removeClass("carrinho");
}

/**
* Abrir overlay
*/
export function showOverlay(overlay) {
	const element = document.getElementById(overlay);
	if (!element) return;
	const modal = new Modal(element);
	modal.show();
}

/**
* Fechar overlay
*/
export function hideOverlay(overlay, delaytime = 0) {
	const element = document.getElementById(overlay);
	if (!element) return;
	const modal = new Modal(element);
	if (delaytime === 0) {
		return modal.hide();
	}
	setTimeout(() => modal.hide(), delaytime);
}

/* Validar forms */
export function validateForm(form) {
	if (form.hasClass('mkx-validator')) { // Classe para usar o validador
		var valid = true; // Define previamente como válido
		var fields = ""; // Campos que estão com erro
		if (form.find('.check-validator').length > 0) { // Classe para validar checks
			form.find('.check-validator').each(function () {
				if ($(this).find('input:checked').length < 1) {
					valid = false;
					if ($(this).attr('data-alert') != "") {
						fields += '<li>- <strong>' + $(this).attr('data-alert') + '</strong></li>';
					}
					$(this).addClass('not-valid');
				}
			});
		}
		if (form.find('.required').length > 0) {
			form.find('.required').each(function () {
				if ($(this).val() == "" || $(this).val() == undefined || $(this).val() == '00/00/0000') { // Valor vazio
					valid = false;
					if ($(this).attr('data-alert') != "") {
						fields += '<li>- <strong>' + $(this).attr('data-alert') + '</strong></li>';
					}
					$(this).parent().parent().addClass('has-error');
				} else if (!!$(this).attr('data-confirm')) { // se for um campo de confirmação de outro campo (ex: confirme seu e-mail, sua senha)
					if ($(this).val() != form.find($(this).attr('data-confirm')).val()) {
						valid = false;
						fields += '<li>- <strong>' + $(this).attr('data-confirm-alert') + '</strong></li>';
						$(this).parent().parent().addClass('has-error');
					}
				}
			});
		}
		if (valid == false) {
			form.find('.form-success').slideUp();
			form.find('.main-alert').html('<a class="close" title="Fechar">×</a>');
			form.find('.main-alert').append(form.find('.main-alert').attr('data-alert'));
			form.find('.main-alert').append('<ul>' + fields + '</ul>');
			form.find('.main-alert').fadeIn();
			form.find('.main-alert').removeClass("displaynone");
			form.find('.not-valid .alert').fadeIn();
			return false;
		} else {
			return true;
		}
	} else {
		return true;
	}
}

export function copyTextToClipboard(text) {
	if (!navigator.clipboard) {
		const textArea = document.createElement('textarea');
		textArea.value = text;
		textArea.style.top = "0";
		textArea.style.left = "0";
		textArea.style.position = "fixed";
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			const success = document.execCommand('copy');
			const msg = success ? 'successful' : 'unsuccessful';

		} catch (err) { }
		document.body.removeChild(textArea);
		return true;
	}
	navigator.clipboard.writeText(text).then(function () {
	}).catch(function (err) { console.error(err); })
	return true;
}


/* Forms em ajax */
export function ajaxForms(form) {

	if (form.hasClass('ajax')) {
		pageLoadingOn();
		var url = form.attr('action');
		var data = form.serialize();
		data = data + "&ajax=";
		$.ajax({
			type: "POST",
			url: url,
			data: data,
			dataType: "json",
			success: function (res) {
				if (res.reset != undefined) {
					if (res.reset == 1) {
						$(':input', '#' + form.attr('id')).not(':button, :submit, :reset, :hidden').val('').removeAttr('checked').removeAttr('selected');
					}
				}
				if (res.error != undefined) {
					form.find('.form-success').slideUp();
					form.find('.main-alert').html('<a class="close" title="Fechar">×</a>');
					form.find('.main-alert').append(res.error);
					//form.find('.main-alert').fadeIn();
					form.find('.main-alert').removeClass("displaynone");
					if (form.find('.secure-image').length > 0) {
						form.find('.secure-image #reload-image').trigger('click');
					}
					pageLoadingOff();
					return false;
				} else if (res.location != undefined) {
					window.location.href = res.location;
				} else if (res.html != undefined && res.id != undefined) {
					$("#" + res.id).html(res.html);
					if (res.addressId != undefined) {
						$("#" + res.id).attr("id", "address-" + res.addressId);
						$("input[name=address_id]").val(res.addressId);
					}
					// NEWMKX
					//$('input.star').rating();
					//hideOverlay("overlay-login",0);
					if (res.success != undefined) {
						form.find('.main-alert').slideUp();
						form.find('.form-success').removeClass('displaynone');
						form.find('.form-success').html('<a class="close" title="Fechar">×</a>');
						form.find('.form-success').append(res.success);
						form.find('.form-success').fadeIn();
					}

					hideOverlay("overlay-address-update", 2500);

					pageLoadingOff();
				} else if (res.customEffect != undefined) {
					switch (res.customEffect) {
						case "checkoutPersonUpdate": // Checkout 2 - Atualizar dados do cliente
							var firstName = form.find('#personName').val().split(" ");
							firstName = firstName[0];
							$('#step-1 .person-data .hello span').html(firstName);
							$('#step-1 .person-data .name span').html(form.find('#personName').val());
							$('#step-1 .person-data .document span').html(form.find('#personCPF').val());
							$('#step-1 .person-data .phone span').html(form.find('#personPhone').val());
							$('#step-1 .person-data .email span').html(form.find('#personEmail').val());
							// NEWMKX
							hideOverlay("overlay-person-update", 0);
							pageLoadingOff();
							break;
					}
				} else if (res.success != undefined) {
					form.find('.main-alert').slideUp();
					form.find('.form-success').removeClass('displaynone');
					form.find('.form-success').html('<a class="close" title="Fechar">×</a>');
					form.find('.form-success').append(res.success);
					form.find('.form-success').fadeIn();
					if (form.hasClass('resetFields')) {
						form.trigger("reset");
					}
					var loginTrigger = form.find('input[name=loginTrigger]').val();

					if (res.nItens != undefined) {
						if (res.nItens == 1) {
							$("#cart-box p strong").html("0" + res.nItens + " produto");
						} else {
							if (res.nItens < 10) {
								$("#cart-box p strong").html("0" + res.nItens + " produtos");
							} else {
								$("#cart-box p strong").html("" + res.nItens + " produtos");
							}
						}
						$("#cart-box span strong").html(res.total);
					}

					pageLoadingOff();
					// NEWMKX
					//hideOverlay("overlay-homologacao",0);
					//hideOverlay("overlay-add-cart",1000);

					if (loginTrigger == 'ok') {
						// NEWMKX
						//hideOverlay("overlay-login",0);
						$('.add-favorites a').attr("data-login", '');
						$('.add-favorites a').trigger('click');
					}

					if (url == '/meus-enderecos/') {
						setTimeout(function () {
							window.location.reload();
						}, 2000);
					}
				}
			},
			error: function () {
				form.find('.form-success').slideUp();
				form.find('.main-alert').html('<a class="close" title="Fechar">×</a>');
				form.find('.main-alert').append('Erro, tente denovo!');
				//form.find('.main-alert').fadeIn();
				form.find('.main-alert').removeClass("displaynone");
				if (form.find('.secure-image').length > 0) {
					form.find('.secure-image #reload-image').trigger('click');
				}
				pageLoadingOff();
				return false;
			}
		});

	} else {
		return true;
	}

}

export function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}
