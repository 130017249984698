import { pageLoadingOn, pageLoadingOff } from './utilities.js';

jQuery(async function () {

	const { showOverlay, hideOverlay } = await import('./utilities.js')

	// Popular endereço via cep
	$("body").on("blur", ".zipcode input", setAddress);

	// Popular a cidades através do estado
	$("body").on("change", ".state select", setCities);

	if ($("input#firstAddress").length > 0) {
		$("input#firstCep").trigger("blur");
	}

	// Adiciona um endereço
	$("body").on("click", ".novo-endereco", function () {
		$.ajax({
			type: "GET",
			url: "/meus-enderecos/",
			data: "newAddress",
			dataType: "json",
			success: function (res) {
				if (res.success != undefined) {
					$("#overlay-address-insert").remove();
					$("body").prepend(res.success);
					showOverlay("overlay-address-insert");
				} else if (res.error != undefined) {
					alert(res.error);
				}
			}
		});
	});

	// Altera um endereço
	$("body").on("click", ".editar-endereco", function () {
		var id = $(this).attr("rel");
		$.ajax({
			type: "POST",
			url: "/meus-enderecos/",
			data: "editAddress=" + id,
			dataType: "json",
			success: function (res) {
				if (res.success != undefined) {
					$("#overlay-address-update").remove();
					$("body").prepend(res.success);
					showOverlay("overlay-address-update");
				} else if (res.error != undefined) {
					alert(res.error);
				}
			}
		});
	});

	// Exclui um endereço
	$("body").on("click", ".excluir-endereco", function () {
		var id = $(this).attr("rel");
		var name = $(this).attr("name");

		$("#overlay-address-delete span.name").html(name);
		$("#overlay-address-delete input[name=addressId]").val(id);
		showOverlay('overlay-address-delete');
	});

	$("body").on("click", "#overlay-address-delete button.btn-excluir", function () {
		var id = $("#overlay-address-delete input[name=addressId]").val();
		$.ajax({
			type: "POST",
			url: "/meus-enderecos/",
			data: "deleteAddress=2&id=" + id,
			dataType: "json",
			success: function (res) {
				if (res.success != undefined) {
					pageLoadingOn();
					window.location.reload(true);
				} else if (res.error != undefined) {
					alert(res.error);
				}
			}
		});
	});
});


/* Definir endereço pelo CEP */
var actualZipcode = null;
window.actualCity = null;
export function setAddress() {
	var zipcode = $(this).val();

	var target = $(this).parent().parent().parent().parent().attr("id");

	if ($(this).hasClass("overlay-address")) {
		target = $(this).parent().parent().parent().parent().parent().parent().attr("id");
	}

	if (zipcode == "" || zipcode == actualZipcode || $('#' + target + ' .address input').length == 0) {
		return;
	}
	actualZipcode = zipcode;
	pageLoadingOn();
	$.ajax({
		type: "GET",
		url: "/meus-enderecos/",
		data: "zipcode=" + zipcode,
		dataType: "json",
		timeout: 20000, // 20 segundos de espera
		success: function (json) {
			$("#" + target + " .address input").val(json.address);
			$("#" + target + " .region input").val(json.region);
			$("#" + target + " .state select").val(json.state_id);
			window.actualCity = json.city_id;
			$("#" + target + " .state select").trigger('change');
			pageLoadingOff();
		},
		error: function (jqXHR, textStatus, errorThrown) {
			if (textStatus === "timeout") {
				pageLoadingOff();
				bootbox.dialog({
					message: "Desculpe, houve uma instabilidade, a página será recarregada, favor tente novamente",
					title: "Instabilidade no servidor",
					buttons: {
						success: {
							label: "Ok",
							className: "btn-success",
							callback: function () {
								window.location.reload(true);
							}
						}
					}
				});
			}
		}
	});
}

/* Definir as cidades atarvés do estado */
var waitForCity = false;
export function setCities() {
	if (!waitForCity) {
		waitForCity = true;
		var stateId = $(this).val();
		var cep = "";
		var target = $(this).parent().parent().parent().parent().attr("id");

		if ($(this).hasClass("overlay-address")) {
			target = $(this).parent().parent().parent().parent().parent().parent().attr("id");
		}

		if ($("#" + target + " input[name=zipcode]").length > 0) {
			cep = $("#" + target + " input[name=zipcode]").val();
		}
		pageLoadingOn();
		$.ajax({
			type: "GET",
			url: "/meus-enderecos/",
			data: "stateId=" + stateId + "&cep=" + cep,
			dataType: "json",
			timeout: 20000,
			success: function (json) {
				$("div#addressSelect").html(json.success);
				$("#" + target + " .city select").val(window.actualCity);
				pageLoadingOff();
				waitForCity = false;
			},
			error: function (jqXHR, textStatus, errorThrown) {
				if (textStatus === "timeout") {
					pageLoadingOff();
					bootbox.dialog({
						message: "Desculpe, houve uma instabilidade, a página será recarregada, favor tente novamente",
						title: "Instabilidade no servidor",
						buttons: {
							success: {
								label: "Ok",
								className: "btn-success",
								callback: function () {
									window.location.reload(true);
								}
							}
						}
					});
				}
			}
		});
	}
}