/**
 * Classe responsavel pelos dados dos produtos no data layer
 */
export class DataLayerHandler {
  static layer = [];
  /**
   * Preenche a variavel layer com os dados da tag script com id mkxLayer
   */
  static init() {
      const items = [];
      document.querySelectorAll("script.mkxLayer")?.forEach(script => {
        try {
          if(script.textContent) items.push(...JSON.parse(script.textContent));
        } catch(e) {}
      });
      this.layer = this.removeDuplicatedItems(items, 'item_id');
  }
  /**
   * retorna os dados de um produto atraves de seu id ou elemento.
   */
  static getProductData({ id, element }) {
    const productId = id ?? this.getProductIdFromElement(element);
    return this.getProductDataById(productId);
  }

  /**
   * Produra o elemento que tem o id do produto.
   */
  static getProductIdFromElement(element) {
    return element.getAttribute('data-product')
      ?? element.getAttribute('data-id')
      ?? element.querySelector("[data-id]")?.getAttribute('data-id')
      ?? element.querySelector("[data-productId]")?.getAttribute('ata-productId')
      ?? element.querySelector("input[name=product_id]")?.value;
  }
  /**
   * Procura no layuer um produto pelo seu id e o retorna
   * 
   * @param id
   */
  static getProductDataById(id) {
    return this.layer.find(p => p.item_id == id);
  }

  /**
   * Retorna os dados do produto atraves dos elementos input
   * 
   * @param element Element Pai onde os elementos inputs com dados do produto são nodes 
   */
  static getProductDataByInputs(element) {
    const id = element.querySelector("input[name=productIds\\[\\]]")?.value ?? element.querySelector("input[name=productId")?.value;
    const code = element.querySelector("input[name=productCodes\\[\\]]")?.value ?? element.querySelector("input[name=productCode")?.value;
    const name = element.querySelector("input[name=productNames\\[\\]]")?.value ?? element.querySelector("input[name=productName]")?.value;
    const manufacturers = element.querySelector("input[name=productManufacturers\\[\\]")?.value ?? element.querySelector("input[name=productManufacturer]")?.value;
    const categories = element.querySelector("input[name=productCategories\\[\\]]")?.value ?? element.querySelector("input[name=productCategory]")?.value;
    const price = element.querySelector("input[name=productPrices\\[\\]]")?.value ?? element.querySelector("input[name=productPrice]")?.value;
    const priceOf = element.querySelector("input[name=productPrices\\[\\]]")?.value ?? element.querySelector("input[name=productPricesOf")?.value;
    const quantity = element.querySelector("input[name=amount\\[\\]]")?.value ?? element.querySelector("input#amount")?.value ?? 1;
    const sku = element.querySelector("input[name=productVariants\\[\\]]")?.value;

    return { id, code, name, quantity, price: Number(price ?? priceOf), manufacturers, categories, sku, currency: 'BRL' };
  }

  /**
   * Retorna os dados de um produto no HTML
   * 
   * @param Element element
   * 
   * @return object { sku: string, price: string, amount: string }
   */
  static getDataFromHtml(element) {
    const price = element.querySelector("[data-price]")?.getAttribute("data-price");
    const quantity = element.querySelector("input#amount")?.value 
      ?? element.querySelector("input[name=amount]")?.value
      ?? element.querySelector("[data-amount]")?.getAttribute("data-amount");
    const sku = Array.from(
      element.querySelectorAll("[data-sku-option-name]")
      ).reduce((skuName, skuElement) => skuName + " " + skuElement.dataset.skuOptionName, '');
    return { item_variant: sku.trim(), price, quantity };
  }

    /**
   * Retorna os Itens da lista na página do Checkout
   */
  static getProductsDataFromPurchaseList() {
    const productsData = [];
    const purchaseListPage = document.querySelectorAll("#form-purchase-list .purchase-list-item");
    const purchaseListCheckout = document.querySelectorAll(".purchase-list-resume-items .item");
    const purchaseListSidebar = document.querySelectorAll("#miniCart li[data-purchaselist]");
    const listItens = (purchaseListPage.length && purchaseListPage) 
                    || (purchaseListCheckout.length && purchaseListCheckout) 
                    || (purchaseListSidebar.length && purchaseListSidebar)
                    || null; 
    listItens?.forEach(item => {
      const product = this.getProductData({ element: item });
      if (!product) return;
      const { price, item_variant, quantity } = this.getDataFromHtml(item);
      product.item_variant = item_variant;
      product.price = !isNaN(price) ? parseFloat(price) : parseFloat(product.price);
      product.quantity = !isNaN(quantity) ? parseFloat(quantity) : 1;
      productsData.push(product);
    });
    return productsData;
  }

  static getPurchaseDetails() {
    let shipping = document.querySelector("[data-shipping]")?.dataset.shipping ?? 0;
    let rate = document.querySelector("[data-rate]")?.dataset.rate ?? 0;
    let totalProduct = document.querySelector("[data-totalProduct]")?.dataset.totalproduct ?? 0;
    let coupon = document.querySelector("[data-coupon]")?.dataset.totalproduct ?? 0;
    let transaction_id = document.querySelector("[data-orderId]")?.dataset.orderid;
    shipping = parseFloat(shipping);
    rate = parseFloat(rate);
    totalProduct = parseFloat(totalProduct);
    coupon = parseFloat(coupon);
    
    const revenue = shipping + rate + totalProduct;

    return { transaction_id, shipping, rate, totalProduct, revenue, tax: 0, coupon };
  }

  static removeDuplicatedItems(arr, prop) {
    return arr.reduce((prev, current) => {
      const foundDuplicated = prev.find(item => item[prop] === current[prop]);
      return !foundDuplicated ? prev.concat([current]) : prev;
    }, []);
  }
}
